
#search_q{
  width: 90%;
}


#update_img {
    width: 80%;
    padding: 60px;
  } 
  
  .hidden {
    opacity: 0;
    display: none;
  }
  .text-muted1{
      font-size: 40px;
      font-weight: bold;
    
  }
  .search-container {
    display: flex;
    margin: 10px;
    width: 90%;
  }
  input:focus {
    border: 1px solid #be13d8d6;
    box-shadow: none!important;
  }
  .btn-search {
    border: none;
    width: 7%;
    padding-bottom: 10px 25px;
    border-radius: 0 5px 5px 0;
    background-color: #be13d8d6;
    color: #fff;
    margin-left: -15px;
    cursor: pointer;
  }
  .no-border {
    border: none !important;
  }
  .flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 30px 15px;
    background-color: #efefef;
    color: #63405c;
  }
  
  .pokemon-card {
    perspective: 500px;
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    height: 90vh;
    background-size: cover;
    border: 1px solid rgba(255, 255, 255, .25);
    border-radius: 4px;
    position: relative;
    transform-style: preserve-3d;
    will-change: transform;
    transition: transform .5s;
  }
   

  .pokemon-card:hover{
    transform: translateZ(10px) rotateY(30deg) rotateX(20deg);
  }

  .cp-text span {
    font-size: 16px;
  }
  
  .img-container {
    position: relative;
    z-index: 2;
    border-radius: 50%;
    text-align: center;
  }
  

  
  .detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:100px 30px 60px;
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
    margin-top: -150px;
    box-shadow: 0 0 8px rgba(0,0,0,0.3);
  }
  .seperator {
    border-color: #b56eec !important;
    border-width: 10px;
    border-radius: 5px;
  }
  
  .btn-transfer {
    margin: 45px 0;
    padding: 15px 30px;
    background: linear-gradient(to right, #c893da, #b222cb);
    border-radius: 25px;
    border: none;
    color: #fff;
    min-width: 60%;
  }
  .stats span.first {
    border-right:1px solid #eee
  }
  
  .attributes-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    text-transform: capitalize;
  }
  .cp-text {
    padding-right: 10px;
    border-right: 1px solid #eee;
    margin: 0;
  }
  .cp-text:last-child {
    border: none;
  }
  .player-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 15px;
    border-top: 1px solid #eee;
    padding-top: 15px;
    text-align: center;
  }
  .data-container p {
    margin-bottom: 0;
  }
  
  #update_name, #update_candy_title{
    text-transform: capitalize;
  }