.container{
     width: 90%;
     margin: auto;  
     padding-top: 60px;
     display: flex;
}


 .left-content,.right-content{
    flex-basis: 60%;
} 

.right-content{
    width: 50%;
    text-align: center;
    color: rgb(5, 5, 5);
    position:fixed; 
    top: 140px;
     right: 15px; 
}

.right-content img{
   margin: 2rem;
   height: 180px;
   
}

.right-content h1{
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    
}

.Habilidades{
    width: 30%;
    margin:auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
}

.Habilidades .Grupo{
background-color: #ff6fd6;
color: white;
padding: 0.5rem;
font-size: 12px;
border-radius:8px;
}

.estado-base{
    margin-top: 2rem;
}


.Card{
    width: 350px; 
    background-color:#b17df69d;
    border-radius:1rem;
    box-shadow: 0 5px 5px #8739a0;
    padding: 0 1.8rem;  
    display: flex; 
    align-items: center;
    justify-content: space-between;  
    box-sizing: border-box; 
}

.img{
    width: 100px;
    height: 90px;
}

.btn-group button{
    outline: none;
    border: none;
    width: 100px;
    height: 50px;
    padding:0.3rem 0;
    margin: 1rem;
    font-size: 17px;
    font-weight: bold;
    color: white;
    background-color: #ff6fd6;
    border-radius: 8px;
}